<template>
    <section class="invoice-add-wrapper">
      <!-- Alert: No item found -->
  
      <b-row class="invoice-add">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
  
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <b-card
                no-body
                class="invoice-preview-card"
              >
                <!-- Header -->
                <b-card-body>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="3"
                    >
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
  
                          <b-form-group
                            label=" نوع العملية"
                            label-for="payment-method"
                            :state="errors.length > 0 ? false : null"
                          >
                            <v-select
                              v-model="Form.type_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="warehouseTRType"
                              disabled
                              :reduce="(val) => val.value"
                              class="mb-2 item-selector-title"
                            />
                          </b-form-group>
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </div>
                    </b-col>
                    <b-col
                md="3"
                xl="4"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> تاريخ الاستلام </label>
                    <flat-pickr
                      v-model="Form.receive_date"
                      class="form-control"
                      placeholder="حدد تاريخ "
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
                   
                
                    <!-- <b-col
                      cols="12"
                      lg="3"
                    >
                      <div class="">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
  
                          <b-form-group
                            label="user_material_order_id"
                            label-for="payment-method"
                            :state="errors.length > 0 ? false : null"
                          >
                            <b-form-input
                              v-model="Form.user_material_order_id"
                              t
                              class="mb-2"
                            />
                          </b-form-group>
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </div>
                    </b-col> -->
                    <b-col
                      cols="12"
                      md="4"
                    >
                    <div class="">
                      <b-form-group
                        label="القسم"
                        label-for="user-department"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="department"
                          rules="required"
                        >
                          <v-select
                            v-model="Form.department_id"
                            :options="departmentOptions"
                            label="name"
                            :reduce="(val) => val.id"
                            input-id="user-role"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                    <div class="">
                      <b-form-group
                        label="الموظف الذي قام بالطلب"
                        label-for="user-department"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="user"
                          rules="required"
                        >
                          <v-select
                            v-model="Form.request_by_user"
                            :options="usersOptions"
                         
                            :reduce="(val) => val.value"
                            input-id="user-role"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      </div>
                    </b-col>
                  
                  
                    <b-col
                      cols="12"
                      md="4"
                      xl="3"
                      class="invoice-actions"
                    >
                      <!-- Payment Method -->
                      <div class="">
                        <b-form-group
                          label="Cost Center"
                          label-for="payment-method"
                        />
                        <b-form-input
                          v-model="Form.cost_center"
                          type="text"
                        />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                      xl="3"
                      
                    >
                      <!-- Payment Method -->
                      <div class="">
                        <b-form-group
                          label=" مكان الاستلام "
                          label-for="payment-method"
                        />
                        <b-form-input
                          v-model="Form.receive_place"
                          type="text"
                        />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                      </div>
                    
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                      xl="3"
                      
                    >
                      <!-- Payment Method -->
                      <div class="">
                        <b-form-group
                          label="target"
                          label-for="target"
                        />
                        <b-form-input
                          v-model="Form.target"
                          type="number"
                        />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                      xl="3"
                      class="invoice-actions"
                    >
                      <!-- Payment Method -->
                      <div class="">
                        <b-form-group
                          label=" PPa Number"
                          label-for="payment-method"
                        />
                        <b-form-input
                          v-model="Form.ppa_number"
                          type="text"
                        />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                      xl="3"
                      class="invoice-actions"
                    >
                      <!-- Payment Method -->
                      <div class="">
                        <b-form-group
                          label=" ملاحظة"
                          label-for="payment-method"
                        />
                        <b-form-input
                          v-model="Form.notes"
                          type="text"
                        />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                      </div>
                    </b-col>
                  </b-row>
  
                </b-card-body>
  
                <!-- Spacer -->
                <hr class="invoice-spacing">
  
            
                <!-- Items Section -->
                <b-card-body class="invoice-padding form-item-section">
                  <div
                    ref="form"
                    class="repeater-form"
                    :style="{ height: trHeight }"
                  >
                    <b-row
                      v-for="(item, index) in Form.details"
                      :key="index"
                      ref="row"
                      class="pb-2"
                    >
                      <!-- Item Form -->
                      <!-- ? This will be in loop => So consider below markup for single item -->
                      <b-col cols="12">
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="12"
                            >
                              <label>
                                التفاصيل
                              </label>
                            </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>
  
                        <!-- Form Input Fields OR content inside bordered area  -->
                        <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-group
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <label class="d-inline d-lg-none">المادة</label>
                                  <v-select
                                    v-model="item.item_id"
                                    :dir="
                                      $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                    "
                                    :options="ItemData"
                                    label="name"
  
                                    :clearable="false"
                                    :reduce="(val) => val.id"
                                    class="mb-2 item-selector-title"
                                    placeholder="المادة "
                                  />
                                </b-form-group>
                                <b-form-invalid-feedback
                                  :state="errors.length > 0 ? false : null"
                                >
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="3"
                            >
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-group
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <label class="d-inline d-lg-none">الكمية</label>
                                  <b-form-input
                                    v-model="item.quantity"
                                    type="number"
                                    class="mb-2"
                                    placeholder="الكمية "
                                  />
                                </b-form-group>
                                <b-form-invalid-feedback
                                  :state="errors.length > 0 ? false : null"
                                >
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-group
                                  label-for="notes"
                                  :state="errors.length > 0 ? false : null"
                                >
                                  <label
                                    class="d-inline d-lg-none"
                                  >الواحدة</label>
                                  <v-select
                                    v-model="item.unit_id"
                                    :dir="
                                      $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                    "
                                    :options="ubitsOption"
                                    label="en_name"
                                    :clearable="false"
                                    :reduce="(val) => val.id"
                                    class="mb-2 item-selector-title"
                                    placeholder="الواحدة "
                                  />
  
                                </b-form-group>
                                <b-form-invalid-feedback
                                  :state="errors.length > 0 ? false : null"
                                >
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-col>
                          
  
                          </b-row>
                          <div
                            class="d-flex flex-column justify-content-between border-left py-50 px-25"
                          >
                            <feather-icon
                              v-if="index != 0"
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeItem(index)"
                            />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    @click="addNewItemInItemForm"
                  >
                    <span>
                      اضافة مادة اخرى
                    </span>
                  </b-button>
                </b-card-body>
  
                <!-- Invoice Description: Total -->
  
                <!-- Spacer -->
                <hr class="invoice-spacing">
                <b-col
                  class="border-Primary mb-10"
                  md="12"
                  xl="4"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-50 mb-10"
                    />
                    <span class="align-middle"> انشاء</span>
                  </b-button>
                 
                </b-col>
              <!-- Note -->
              </b-card>
            </b-form>
          </validation-observer>
        </b-col>
  
        <!-- Right Col: Card -->
      </b-row>
      <b-row />
  
      <!-- <invoice-sidebar-send-invoice />
          <invoice-sidebar-add-payment /> -->
    </section>
  </template>
  
  <script>
  import Logo from '@core/layouts/components/Logo.vue'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { heightTransition } from '@core/mixins/ui/transition'
  import Ripple from 'vue-ripple-directive'
  import Vue from 'vue'
  import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
  
    BForm,
    BFormGroup,
    BFormInput,
  
    BFormInvalidFeedback,
  
    VBToggle,
  } from 'bootstrap-vue'
  import { required } from '@validations'
  import vSelect from 'vue-select'
  import flatPickr from 'vue-flatpickr-component'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import financeStoreModule from './financeStoreModule.js'
  import store from '@/store'
  
  export default {
    components: {
      BRow,
      BCol,
      required,
      ValidationProvider,
      ValidationObserver,
      financeStoreModule,
      BFormInvalidFeedback,
      BCard,
      BCardBody,
      BButton,
  
      BForm,
      BFormGroup,
      BFormInput,
  
      // eslint-disable-next-line vue/no-unused-components
      flatPickr,
      vSelect,
  
    },
    directives: {
      Ripple,
      'b-toggle': VBToggle,
    },
    mixins: [heightTransition],
  
    // Reset Tr Height if data changes
    watch: {
      // eslint-disable-next-line func-names
      'invoiceData.items': function () {
        this.initTrHeight()
      },
    },
    mounted() {
      this.initTrHeight()
    },
    created() {
      window.addEventListener('resize', this.initTrHeight)
    },
    destroyed() {
      window.removeEventListener('resize', this.initTrHeight)
    },
    methods: {
  
      initTrHeight() {
        this.trSetHeight(null)
        this.$nextTick(() => {
          this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
        })
      },
    },
    setup() {
      const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'
  
      // Register module
      if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
      })
  
      const Form = ref({
        type_id: 1,
        from_party_id: null,
     
        user_material_order_id: 1,
        request_by_user: null,
        logistic_officer_signature: null,
        project_manager_signature: null,
        receive_date:'',
        receive_place:'',
        target: null,
        cost_center: null,
        department_id: '',
        ppa_number: null,
        notes: '',
        details: [
          {
            item_id: '',
            quantity: 1,
            notes: '',
            unit_id: '',
          },
        ],
      })
      const invoiceData = ref(null)
      const accountList = ref([])
      const SubAccountList = ref([])
  
      const itemFormBlankItem = {
        item: null,
        cost: 0,
        qty: 0,
        description: '',
      }
  
      const itemsOptions = ref([
        {
          item_id: '',
          quantity: null,
  
          unit_id: '',
      
        },
  
      ])
      const addNewItemInItemForm = () => {
        Form.value.details.push(
          {
            item_id: '',
            quantity: null,
  
            unit_id: '',
         
  
          },
        )
      }
      const removeItem = index => {
        Form.value.details.splice(index, 1)
      }
      const ItemData = ref([])
      const optionCurrency = ref([])
      store.dispatch('app-finance/GetCurrency').then(response => {
        //console.log('ubitsOption,', optionCurrency)
        optionCurrency.value = response
      })
      const ubitsOption = ref([])
      store.dispatch('app-finance/GetUnits').then(response => {
        //console.log('ubitsOption,', ubitsOption)
        ubitsOption.value = response
      })
      store.dispatch('app-finance/GetItem').then(response => {
        //console.log(response)
        ItemData.value = response
      })
      const updateItemForm = (index, val) => {
        const { cost, qty, description } = val
        invoiceData.value.items[index].cost = cost
        invoiceData.value.items[index].qty = qty
        invoiceData.value.items[index].description = description
      }
      const donorOption = ref([])
      const billerOption = ref([])
      store.dispatch('app-finance/GetDonor').then(response => {
        //console.log(response)
        donorOption.value = response
      })
      store.dispatch('app-finance/GetBiller').then(response => {
        //console.log(response)
        billerOption.value = response
      })
      const outComeOption = ref([])
      const outPutOption = ref([])
      const partTypeTo = ref('')
      store.dispatch('app-finance/GetOutCome').then(response => {
        //console.log(response)
        outComeOption.value = response
      })
      store.dispatch('app-finance/GetoutPut').then(response => {
        //console.log(response)
        outPutOption.value = response
      })
      store.dispatch('app-finance/GetAccount').then(response => {
        //console.log(response)
        accountList.value = response
      })
      const partOptionTo=ref([])
      const getPartyTypeTo = id => {
        partOptionTo.value = []
        store.dispatch('app-finance/getPartyType', { id }).then(response => {
          // console.log('ubitsOption,', response)
          if (id == 1) {
            response.data.forEach(el => {
              if(el.details.first_name){
  
            
              partOptionTo.value.push({
                id: el.id,
                name: el.details.first_name,
              })
            }
            })
          } else {
            response.data.forEach(el => {
              if(el.details.name){
                partOptionTo.value.push({
                id: el.id,
                name: el.details.name,
              })
              }
             
            })
          }
        })
      }
      const getSubAccount = id => {
        SubAccountList.value = []
        store.dispatch('app-finance/getSubAccount', { id }).then(response => {
          //console.log('ubitsOption,', response)
  
          response.forEach(el => {
            SubAccountList.value.push({
              id: el.id,
              name: el.name,
            })
          })
        })
      }
      const departmentOptions = ref([])
      store.dispatch('app-finance/GetDepartment').then(response => {
   //  console.log(response)
        departmentOptions.value = response
      })
      const partOption = ref([])
  
      const getPartyType = id => {
        partOption.value = []
        store.dispatch('app-finance/getPartyType', { id }).then(response => {
          //console.log('ubitsOption,', response)
          if (id == 1) {
            response.data.forEach(el => {
              partOption.value.push({
                id: el.id,
                name: el.details.first_name,
              })
            })
          } else {
            response.data.forEach(el => {
              partOption.value.push({
                id: el.id,
                name: el.details.name,
              })
            })
          }
        })
      }
  
      const warehouseTRType = [
        {
          value: 1,
          label: 'شراء',
        },
        {
          value: 2,
          label: 'نقل',
        },
        {
          value: 3,
          label: 'مرتجع',
        },
        {
          value: 4,
          label: 'تلف',
        },
        {
          value: 5,
          label: 'توزيع',
        },
      ]
      const partType = ref('')
      const usersOptions=ref([])
      store.dispatch('app-finance/GetUsers').then(response => {
        //console.log(response)
        usersOptions.value=[]
       
        response.forEach((el) => {
          usersOptions.value.push({ value: el.id, label: `${el.first_name} ${el.last_name} `
 }); });
      })
      const partTypeOption = [
        {
          value: 1,
          label: 'مستخدم',
        },
        {
          value: 2,
          label: 'مستودع',
        },
        {
          value: 3,
          label: 'مورد',
        },
      ]
      const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
      const onSubmit = () => {
        //console.log(Form)
  
        store.dispatch('app-finance/createTransaction', Form.value).then(response => {
          Form.value.type_id = '',
          Form.value.from_party_id = '',
          Form.value.to_party_id = '',
          Form.value.notes = '',
          Form.value.details = [{
            item_id: '',
            quantity: 1,
            notes: '',
        
          }]
         
          Form.value.cost_center = null,
       
          Form.value.department_id = '',
          Form.value.ppa_number = '',
          resetForm()
          Vue.swal({
            title: '',
            text: 'تمت أضافة الطلب بنجاح',
            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
      }
      return {
        usersOptions,
        departmentOptions,
        Form,
        getPartyTypeTo,
        partOptionTo,
        partTypeTo,
        refFormObserver,
        getValidationState,
        onSubmit,
        resetForm,
        addNewItemInItemForm,
        removeItem,
        ItemData,
        optionCurrency,
        ubitsOption,
        accountList,
        getSubAccount,
        SubAccountList,
        billerOption,
        donorOption,
        outComeOption,
        outPutOption,
        getPartyType,
        partOption,
        partType,
        partTypeOption,
        invoiceData,
        itemsOptions,
        updateItemForm,
        itemFormBlankItem,
        warehouseTRType,
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
  
  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  
  .form-item-section {
    background-color: $product-details-bg;
  }
  button.btn.btn-outline-primary {
      margin: 1.375rem !important;
  }
  
  .form-item-action-col {
    width: 27px;
  }
  
  .repeater-form {
    // overflow: hidden;
    transition: 0.35s height;
  }
  
  .v-select {
    &.item-selector-title,
    &.payment-selector {
      background-color: #fff;
  
      .dark-layout & {
        background-color: unset;
      }
    }
  }
  
  .dark-layout {
    .form-item-section {
      background-color: $theme-dark-body-bg;
  
      .row .border {
        background-color: $theme-dark-card-bg;
      }
    }
  }
  button.btn.me-20.btn-outline-danger {
    margin-right: 17px;
  }
  </style>
  